import LinksTagList from './LinksTagList'
import MultiSelectTagList from './MultiSelectTagList'
import NonSelectTagList from './NonSelectTagList'
import SingleSelectTagList from './SingleSelectTagList'
import { TagListProps } from './types'

export default function TagList<T>({
  data,
  loading,
  asLinks,
  selectionMode,
  ...props
}: TagListProps<T>) {
  if (loading) {
    if (asLinks) {
      return <LinksTagList {...props} loading asLinks />
    }

    return (
      <>
        {selectionMode === 'multiple' && (
          <MultiSelectTagList {...props} loading selectionMode='multiple' />
        )}
        {selectionMode === 'single' && (
          <SingleSelectTagList {...props} loading selectionMode='single' />
        )}
        {(selectionMode === 'none' || selectionMode == null) && (
          <NonSelectTagList {...props} loading selectionMode='none' />
        )}
      </>
    )
  }

  if (asLinks) {
    return <LinksTagList {...props} data={data} asLinks />
  }

  return (
    <>
      {selectionMode === 'multiple' && (
        <MultiSelectTagList {...props} data={data} selectionMode='multiple' />
      )}

      {selectionMode === 'single' && (
        <SingleSelectTagList {...props} data={data} selectionMode='single' />
      )}

      {(selectionMode === 'none' || selectionMode == null) && (
        <NonSelectTagList {...props} data={data} selectionMode='none' />
      )}
    </>
  )
}
