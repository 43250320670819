import clsx from 'clsx'

import TagContent from './TagContent'
import { NonSelectableTagProps, TagDefaultProps } from './types'

export default function DefaultTag({
  data,
  icon,
  rounded = true,
  className,
  style,
  dataTestId,
  loading,
}: TagDefaultProps & NonSelectableTagProps) {
  return (
    <div
      data-testid={dataTestId}
      className={clsx(
        'flex w-max items-center gap-1 px-2.5 py-1 !transition-all xl:gap-3 xl:px-3 xl:py-1.5',
        {
          accent: 'bg-black text-white',
          white: 'bg-lightgray text-black',
          black: 'bg-darkblack text-white',
          lightBrown: 'bg-ecruwhite text-black',
          darkBrown: 'bg-ecrudark text-black',
        }[data?.color ?? 'white'],
        rounded ? 'radius-pill' : 'radius-xs',
        className,
      )}
      style={style}
    >
      {loading ? <TagContent loading /> : <TagContent data={{ text: data?.text, icon: icon }} />}
    </div>
  )
}
