'use client'

import './TagSliderStyles.css'

import { useEffect, useId, useMemo } from 'react'
import Slider, { type Settings } from 'react-slick'

import Tag from '../Tag'
import { SingleSelectTagListProps, TagListCommonProps } from './types'

export default function SingleSelectTagList<T>({
  data,
  listClassName,
  itemClassName,
  itemStyles,
  roundedTag = true,
  ariaLabel,
  loading,
}: SingleSelectTagListProps<T> & TagListCommonProps) {
  const id = useId()

  useEffect(() => {
    data?.onInit && data.onInit(data?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sliderSettings: Settings = useMemo(
    () => ({
      swipeToSlide: true,
      speed: 500,
      arrows: false,
      dots: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      infinite: false,
      variableWidth: true,
    }),
    [],
  )

  return (
    <ul className={listClassName} aria-label={ariaLabel}>
      {loading ? (
        <>
          {Array.from({ length: 3 }).map((_, index) => (
            <li key={index}>
              <Tag className={itemClassName} rounded={roundedTag} style={itemStyles} loading />
            </li>
          ))}
        </>
      ) : (
        <>
          {data.slider ? (
            // @ts-ignore
            <Slider {...sliderSettings} className='tag-slider isolate'>
              {data.tags.map(({ icon, text, tagValue, dataTestId }, index) => (
                <li key={index}>
                  <Tag
                    data={{
                      text,
                      onSelect: () => data.onSelect(tagValue),
                      isChecked: data.value === tagValue,
                      type: 'radio',
                      inputProps: data.inputProps,
                      name: id,
                    }}
                    dataTestId={dataTestId}
                    icon={icon}
                    selectable
                    rounded={roundedTag}
                    className={itemClassName}
                    style={itemStyles}
                  />
                </li>
              ))}
            </Slider>
          ) : (
            data.tags.map(({ icon, text, tagValue, dataTestId }, index) => (
              <li key={index}>
                <Tag
                  data={{
                    text,
                    onSelect: () => data.onSelect(tagValue),
                    isChecked: data.value === tagValue,
                    type: 'radio',
                    inputProps: data.inputProps,
                    name: id,
                  }}
                  dataTestId={dataTestId}
                  icon={icon}
                  selectable
                  rounded={roundedTag}
                  className={itemClassName}
                  style={itemStyles}
                />
              </li>
            ))
          )}
        </>
      )}
    </ul>
  )
}
