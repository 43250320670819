/**
 * Returns coefficient between 1 and 0
 * @param value total score
 * @param index star index
 */
export const getFillStarCoefficient = (value: number, index: number) => {
  if (Math.floor(value) > index) {
    return 1
  }

  if (value < index) {
    return 0
  }

  return value - Math.trunc(value)
}
