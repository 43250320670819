'use client'

import clsx from 'clsx'
import { ComponentProps, useEffect, useId, useRef, useState } from 'react'

import { sanitizeHtmlId } from '../../helpers/sanitizeId'
import { useWindowSize } from '../../hooks/useWindowSize'
import Icon from '../Icon'
import LoadingText from '../LoadingText'
import AccordionContent from './AccordionContent'

export type Panel = {
  title: string
  subtitle?: string
  content: ComponentProps<typeof AccordionContent>['content']
}

type AccordionListProps = {
  panels?: Panel[]
  shouldTriggerNavigation?: boolean | null
  initialOpenIndex?: number | null
  shouldScrollToFirstAccordion?: boolean
  loading?: boolean
  loadingLength?: number
  loadingWithSubtitles?: boolean
}

export default function AccordionList({
  panels,
  shouldTriggerNavigation = false,
  initialOpenIndex,
  shouldScrollToFirstAccordion = false,
  loading,
  loadingLength = 3,
  loadingWithSubtitles = false,
}: AccordionListProps) {
  const accordionId = useId()
  const { windowSize } = useWindowSize()

  const [openAccordion, setOpenAccordion] = useState<number | null>(initialOpenIndex ?? null)
  const [iconSize, setIconSize] = useState<24 | 32>(32)
  const isFirstRender = useRef(true)

  useEffect(() => {
    setIconSize(windowSize.width >= 1024 ? 32 : 24)
  }, [windowSize.width])

  useEffect(() => {
    if (!shouldTriggerNavigation || !panels) return

    const encodedTitle = window.location.hash.slice(1) ?? ''
    const currentOpen = panels.findIndex(({ title }) => encodedTitle === sanitizeHtmlId(title))

    if (currentOpen !== -1) {
      setOpenAccordion(currentOpen)

      document.getElementById(encodedTitle)?.scrollIntoView()
    }
  }, [panels, shouldTriggerNavigation])

  useEffect(() => {
    if (openAccordion === null && initialOpenIndex !== null && initialOpenIndex !== undefined) {
      setOpenAccordion(initialOpenIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOpenIndex])

  useEffect(() => {
    if (!shouldScrollToFirstAccordion) return
    if (isFirstRender.current) {
      // Skip the effect on the first render when `initialOpenIndex` is set
      isFirstRender.current = false
      return
    }

    if (openAccordion !== null) {
      //scroll to the first accordion item if it's not in view so that the user can see the opened accordion
      const element = document.getElementById(`${accordionId}-heading-0`)
      if (!element) return

      if (element.getBoundingClientRect().top < 0) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccordion])

  return (
    <ul style={{ listStyle: 'none' }}>
      {(loading
        ? new Array(loadingLength).fill(null).map(() => ({
            title: '',
            subtitle: loadingWithSubtitles ? '' : undefined,
            content: [],
          }))
        : panels
      )?.map(({ title, subtitle, content }, i, arr) => {
        const headingId = `${accordionId}-heading-${i}`
        const contentId = `${accordionId}-content-${i}`
        const isSelected = i === openAccordion
        const isLast = i === arr.length - 1

        return (
          <li
            key={headingId}
            className={clsx(
              'border border-x-0',
              isLast ? 'border-y-black' : 'border-b-0 border-t-black',
            )}
          >
            {shouldTriggerNavigation && !loading ? (
              <a
                data-testid='accordion-item'
                id={sanitizeHtmlId(title)}
                role='button'
                aria-expanded={isSelected}
                aria-controls={contentId}
                href={`#${sanitizeHtmlId(title)}`}
                onClick={event => {
                  if (isSelected) {
                    event.preventDefault()
                    history.pushState(null, '', window.location.pathname + window.location.search)
                  }

                  setOpenAccordion(isSelected ? null : i)
                }}
                className='flex w-full items-center justify-between py-6 pr-2 no-underline'
              >
                <div className='flex flex-col gap-1 overflow-hidden'>
                  <h4 className='h5 w-max truncate text-left' id={headingId}>
                    {title}
                  </h4>

                  <p className='sm w-max truncate text-left text-darkgray'>{subtitle}</p>
                </div>

                {isSelected ? (
                  <Icon name='MdRemove' size={windowSize.width >= 1024 ? 32 : 24} />
                ) : (
                  <Icon name='MdAdd' size={windowSize.width >= 1024 ? 32 : 24} />
                )}
              </a>
            ) : (
              <button
                id={title}
                data-testid='accordion-item'
                aria-expanded={isSelected}
                aria-controls={contentId}
                onClick={event => {
                  event.preventDefault()

                  setOpenAccordion(isSelected ? null : i)
                }}
                className='flex w-full items-center justify-between py-6 pr-2'
                disabled={loading}
              >
                <div className='flex flex-col gap-1 overflow-hidden'>
                  <h4 className='h5 w-full truncate text-left' id={headingId}>
                    {loading ? <LoadingText textLength={15} /> : title}
                  </h4>

                  <p className='sm w-full truncate text-left text-darkgray'>
                    {loading && subtitle != null ? <LoadingText textLength={15} /> : subtitle}
                  </p>
                </div>

                {isSelected ? (
                  <Icon name='MdRemove' size={iconSize} />
                ) : (
                  <Icon name='MdAdd' size={iconSize} />
                )}
              </button>
            )}

            <AccordionContent
              headingId={headingId}
              contentId={contentId}
              isSelected={isSelected}
              content={content}
            />
          </li>
        )
      })}
    </ul>
  )
}
