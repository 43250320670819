'use client'

import clsx from 'clsx'
import { Image as ImageType } from 'cms-types'
import Image from 'next/image'
import { CSSProperties, useMemo, useRef } from 'react'

export type PayloadImageProps = {
  /**
   * The image to display
   */
  src?: ImageType
  /**
   * Alt property for the image
   * Is normally extracted from the payload image object, but can be overridden
   */
  alt?: string
  /**
   * Props for the nested img element
   */
  imgProps?: Omit<JSX.IntrinsicElements['img'], 'width' | 'height'>
  /**
   *  Style for the img element
   */
  style?: JSX.IntrinsicElements['img']['style']
  /**
   * Classname for the picture element
   */
  className?: string
  /**
   * Style for the picture element
   */
  pictureStyle?: JSX.IntrinsicElements['picture']['style']
  /**
   * TabIndex for the picture element
   */
  tabIndex?: number
  /**
   * Draggable property for the picture element
   */
  draggable?: boolean
  /**
   * Object-fit property for the img element
   */
  objectFit?: CSSProperties['objectFit']
  /**
   * This prop disables the blur effect which loads a placeholder image with a light grey background
   */
  skipBlur?: boolean
  /**
   * More info: [Mozilla Docs](https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images)
   *
   * Providing a sizes prop, will result in faster loads and better SEO, so do so whenever you can.
   */
  sizes: string
  /**
   * Sets the fetch priority of the image. Only use this for images that are likely the LCP (Largest Contentful Paint) of the page.
   */
  priority?: boolean
  /**
   * Placeholder image will be shown if the image is not available, used for loading states
   */
  showPlaceholder?: boolean
}

const BLUR_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+Pr1+38ACaUD4Vh4Z6sAAAAASUVORK5CYII='

export function PayloadImage({
  src,
  alt,
  imgProps,
  objectFit,
  skipBlur,
  tabIndex,
  sizes,
  className,
  style,
  priority = false,
  pictureStyle,
  draggable,
  showPlaceholder = false,
}: PayloadImageProps) {
  const fill = objectFit === 'cover' || objectFit === 'contain'

  const image = useRef<HTMLImageElement>(null)

  const { avif, webp, png } = useMemo(() => {
    return Object.values(src?.sizes && src?.mimeType !== 'image/svg+xml' ? src.sizes : {}).reduce(
      (acc, { mimeType, url, width }) => {
        if (url == null || width == null) return acc

        if (mimeType === 'image/webp') {
          acc.webp = (acc.webp === '' ? '' : acc.webp + ',') + `${encodeURI(url)} ${width}w`
        }

        if (mimeType === 'image/avif') {
          acc.avif = (acc.avif === '' ? '' : acc.avif + ',') + `${encodeURI(url)} ${width}w`
        }

        if (mimeType === 'image/png') {
          acc.png = (acc.png === '' ? '' : acc.png + ',') + `${encodeURI(url)} ${width}w`
        }

        return acc
      },
      {
        avif: '',
        webp: '',
        png: '',
      },
    )
  }, [src])

  return (
    <picture
      className={clsx(fill && 'relative h-full w-full', className)}
      style={pictureStyle}
      tabIndex={tabIndex}
      draggable={draggable}
    >
      {avif !== '' && <source srcSet={avif} type='image/avif' sizes={sizes} />}
      {webp !== '' && <source srcSet={webp} type='image/webp' sizes={sizes} />}
      {png !== '' && <source srcSet={png} type='image/png' sizes={sizes} />}
      <Image
        {...imgProps}
        ref={image}
        fill={fill}
        style={{
          objectFit,
          objectPosition:
            src?.focalPoint?.x && src?.focalPoint?.y
              ? `${src.focalPoint.x}% ${src.focalPoint.y}%`
              : undefined,
          ...style,
        }}
        unoptimized
        placeholder={skipBlur ? 'empty' : 'blur'}
        blurDataURL={BLUR_DATA_URL}
        src={showPlaceholder ? BLUR_DATA_URL : src?.url ?? ''}
        alt={alt ?? src?.alt ?? ''}
        width={!fill ? src?.width ?? 500 : undefined}
        height={!fill ? src?.height ?? 500 : undefined}
        priority={priority}
      />
    </picture>
  )
}
