/* eslint-disable jsx-a11y/click-events-have-key-events */
'use client'

import clsx from 'clsx'
import { Video } from 'cms-types'
import { useInView } from 'framer-motion'
import { ElementRef, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import Icon from './Icon'

type PayloadVideoProps = {
  src?: Video
  enableControls?: boolean
  playAutomatically?: boolean
  ariaLabel?: string
  containerClassName?: string
} & Omit<JSX.IntrinsicElements['video'], 'src'>

export function PayloadVideo({
  src,
  playAutomatically,
  enableControls = false,
  ariaLabel,
  tabIndex,
  containerClassName,
  ...videoProps
}: PayloadVideoProps) {
  const [isPlaying, setIsPlaying] = useState(!!playAutomatically)

  const videoRef = useRef<ElementRef<'video'>>(null)

  const isInView = useInView(videoRef, { once: true, margin: '50px' })

  const playVideo = useCallback((event?: MouseEvent) => {
    event?.stopPropagation()

    if (!videoRef.current) return

    videoRef.current.play()
    setIsPlaying(true)
  }, [])

  const pauseVideo = useCallback((event?: MouseEvent) => {
    event?.stopPropagation()

    if (!videoRef.current) return

    videoRef.current.pause()
    setIsPlaying(false)
  }, [])

  useEffect(() => {
    if (playAutomatically && !isPlaying) playVideo()
    else if (!playAutomatically && isPlaying) pauseVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playAutomatically])

  return (
    <div
      role='button'
      tabIndex={tabIndex ?? 0}
      aria-label={ariaLabel || 'video'}
      className={clsx('isolate w-full', containerClassName)}
      onClick={playAutomatically ? (isPlaying ? pauseVideo : playVideo) : () => null}
    >
      <div className={clsx('relative transition-[filter]', containerClassName)}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          {...videoProps}
          poster={
            playAutomatically || videoProps.autoPlay
              ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+Pr1+38ACaUD4Vh4Z6sAAAAASUVORK5CYII='
              : typeof src?.poster !== 'string' && src?.poster?.url
              ? src?.poster?.url
              : undefined
          }
          loop
          muted={videoProps.muted === false ? false : true}
          playsInline
          disablePictureInPicture
          autoPlay={playAutomatically || videoProps.autoPlay}
          ref={videoRef}
          src={src?.url ? `${src.url}#t=0.001` : ''}
        >
          {src?.url && isInView && (
            <source src={`${src.url}#t=0.001`} type={src.mimeType || 'video/mp4'} />
          )}
        </video>

        {!videoProps.autoPlay && enableControls && (
          <>
            {isPlaying ? (
              <button onClick={pauseVideo} aria-label='pause' className='absolute bottom-6 right-6'>
                <Icon name='MdOutlinePause' size={24} />
              </button>
            ) : (
              <button onClick={playVideo} aria-label='play' className='absolute bottom-6 right-6'>
                <Icon name='MdOutlinePlayArrow' size={24} />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}
