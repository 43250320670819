'use client'
import { useId } from 'react'

type StarProps = {
  size: number
  fillCoefficient: number
}

export default function Star({ size, fillCoefficient }: StarProps) {
  const gradientId = useId()
  const clipPathId = useId()

  return (
    <svg width={size} height={size} viewBox='0 0 20 20'>
      <defs>
        <linearGradient id={gradientId}>
          <stop offset='0%' stopColor='currentColor' />
          <stop offset={`${fillCoefficient * 100}%`} stopColor='currentColor' />
          <stop offset={`${fillCoefficient * 100}%`} stopColor='transparent' />
          <stop offset='100%' stopColor='transparent' />
        </linearGradient>

        <clipPath id={clipPathId}>
          <path d='M8.237 17.992c.974-.536 2.554-.536 3.526 0l3.25 1.79c.974.535 1.612.048 1.428-1.086l-.621-3.791c-.187-1.136.301-2.710 1.088-3.514l2.63-2.686c.788-.803.543-1.590-.543-1.756l-3.637-.553c-1.088-.165-2.365-1.140-2.852-2.171L10.881.774c-.487-1.032-1.277-1.032-1.762 0l-1.625 3.450C7.006 5.260 5.728 6.232 4.642 6.397l-3.637.553c-1.088.165-1.33.955-.543 1.756l2.63 2.686c.786.805 1.275 2.380 1.088 3.514l-.62 3.79c-.186 1.137.455 1.622 1.426 1.088l3.251-1.79Z' />
        </clipPath>
      </defs>

      <path
        fill={`url(#${gradientId})`}
        clipPath={`url(#${clipPathId})`}
        stroke='currentColor'
        strokeWidth={2}
        d='M8.237 17.992c.974-.536 2.554-.536 3.526 0l3.25 1.79c.974.535 1.612.048 1.428-1.086l-.621-3.791c-.187-1.136.301-2.710 1.088-3.514l2.63-2.686c.788-.803.543-1.590-.543-1.756l-3.637-.553c-1.088-.165-2.365-1.140-2.852-2.171L10.881.774c-.487-1.032-1.277-1.032-1.762 0l-1.625 3.450C7.006 5.260 5.728 6.232 4.642 6.397l-3.637.553c-1.088.165-1.33.955-.543 1.756l2.63 2.686c.786.805 1.275 2.380 1.088 3.514l-.62 3.79c-.186 1.137.455 1.622 1.426 1.088l3.251-1.79Z'
      />
    </svg>
  )
}
