import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { ElementRef, useId, useRef } from 'react'
import { useFocusRing } from 'react-aria'

import TagContent from './TagContent'
import { TagDefaultProps, TagInputProps } from './types'

export default function InputTag({
  data,
  icon,
  rounded = true,
  className,
  style,
  dataTestId,
  loading,
}: TagDefaultProps & TagInputProps) {
  const labelRef = useRef<ElementRef<'label'>>(null)

  const { focusProps, isFocusVisible } = useFocusRing()
  const tagId = useId()
  const isLabelInView = useInView(labelRef)

  return (
    // @ts-ignore
    <label
      {...data?.inputProps}
      data-testid={dataTestId}
      className={clsx(
        'flex cursor-pointer items-center gap-1 border border-opacity-40 py-2.5 !transition-all xl:gap-3',
        {
          accent: 'bg-black text-white',
          white: 'bg-white text-black',
          black: 'bg-darkblack text-white',
          lightBrown: 'bg-ecruwhite text-black',
          darkBrown: 'bg-ecrudark text-black',
          checked: 'bg-lightgray text-black',
        }[data?.isChecked ? 'checked' : 'white'],
        rounded ? 'radius-pill px-5 xl:py-3' : 'radius-xs px-4',
        data?.isChecked ? 'border-black' : 'border-darkgray',
        isFocusVisible && 'outline outline-1 -outline-offset-1',
        className,
      )}
      aria-labelledby={tagId}
      style={style}
      ref={labelRef}
    >
      {isLabelInView && (
        <input
          {...focusProps}
          className='sr-only'
          type={data?.type ?? 'checkbox'}
          checked={data?.isChecked}
          onChange={data?.onSelect}
          name={data?.name}
          readOnly={data?.onSelect == null}
          tabIndex={data?.tabIndex}
        />
      )}

      {loading ? (
        <TagContent loading />
      ) : (
        <TagContent data={{ text: data?.text, tagId: tagId, icon: icon }} />
      )}
    </label>
  )
}
