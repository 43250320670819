import { FC, memo } from 'react'

type ContentProps = {
  content?: (JSX.Element | null)[]
  isSelected: boolean
  contentId: string
  headingId: string
}

const AccordionContent: FC<ContentProps> = ({ content, isSelected, contentId, headingId }) => {
  return (
    <div
      id={contentId}
      aria-labelledby={headingId}
      aria-hidden={!isSelected}
      style={{
        display: 'grid',
        gridTemplateRows: isSelected ? '1fr' : '0fr',
        transition: 'grid-template-rows 500ms',
      }}
      // @ts-ignore
      inert={isSelected ? undefined : ''}
    >
      <div className='overflow-hidden'>
        <div className='pb-8 pt-4'>{content}</div>
      </div>
    </div>
  )
}

export default memo(AccordionContent)
