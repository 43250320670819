'use client'

import DefaultTag from './DefaultTag'
import InputTag from './InputTag'
import LinkTag from './LinkTag'
import { TagActionProps, TagDefaultProps } from './types'

export default function Tag(props: TagDefaultProps & TagActionProps) {
  return props.selectable ? (
    <>{props.asLink ? <LinkTag {...props} /> : <InputTag {...props} />}</>
  ) : (
    <DefaultTag {...props} />
  )
}
