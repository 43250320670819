import isHexColorString from '../../helpers/isHexColorString'
import type { LoadableComponentProps } from '../../types'
import Icon from '../Icon'
import LoadingText from '../LoadingText'

type TagContentProps = LoadableComponentProps<{
  icon?: string | null
  text: string
  tagId?: string
}>

export default function TagContent({ data, loading }: TagContentProps) {
  if (loading)
    return (
      <p className='tag inline-block truncate'>
        <LoadingText textLength={10} />
      </p>
    )

  const { icon, text, tagId } = data
  const hexColor = icon && isHexColorString(icon) ? icon : null

  return (
    <>
      {hexColor && (
        <div className='radius-pill h-4 w-4 xl:h-5 xl:w-5' style={{ backgroundColor: hexColor }} />
      )}

      {!hexColor && icon && (
        <Icon aria-hidden size={16} className='xl:!h-5 xl:!w-5 xl:text-[20px]' name={icon} />
      )}

      <p className='tag inline-block truncate' id={tagId}>
        {text}
      </p>
    </>
  )
}
