import clsx from 'clsx'

import LinkWrapper from '../LinkWrapper'
import TagContent from './TagContent'
import { TagDefaultProps, TagLinkProps } from './types'

export default function LinkTag({
  data,
  icon,
  rounded = true,
  className,
  style,
  dataTestId,
  loading,
}: TagDefaultProps & TagLinkProps) {
  return (
    <LinkWrapper
      data-testid={dataTestId}
      href={data ? data.url : '#'}
      className={clsx(
        'flex cursor-pointer items-center gap-1 border border-opacity-40 py-2.5 no-underline !transition-all focus-visible:outline focus-visible:outline-1 focus-visible:-outline-offset-1 xl:gap-3',
        {
          white: 'bg-white text-black',
          checked: 'bg-lightgray text-black',
        }[data?.isActive ? 'checked' : 'white'],
        rounded ? 'radius-pill px-5 xl:py-3' : 'radius-xs px-4',
        data?.isActive ? 'border-black' : 'border-darkgray',
        className,
      )}
      style={style}
      onClick={e => {
        loading && e.preventDefault()
      }}
      isDisabled={loading}
    >
      {loading ? <TagContent loading /> : <TagContent data={{ text: data?.text, icon: icon }} />}
    </LinkWrapper>
  )
}
