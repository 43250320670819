import Link from 'next/link'
import type { CSSProperties, MouseEventHandler, PropsWithChildren } from 'react'

interface LinkWrapperProps extends PropsWithChildren {
  isDisabled?: boolean
  href: string
  className: string
  'data-testid'?: string
  tabIndex?: number
  onClick?: MouseEventHandler
  style?: CSSProperties
}

export default function LinkWrapper({ isDisabled, children, style, ...rest }: LinkWrapperProps) {
  if (isDisabled) {
    return (
      <div style={{ cursor: 'pointer', ...style }} {...rest}>
        {children}
      </div>
    )
  }

  return <Link {...rest}>{children}</Link>
}
