import Tag from '../Tag'
import { NonSelectTagListProps, TagListCommonProps } from './types'

export default function NonSelectTagList({
  data,
  loading,
  listClassName,
  itemClassName,
  itemStyles,
  roundedTag = true,
  ariaLabel,
}: NonSelectTagListProps & TagListCommonProps) {
  return (
    <ul className={listClassName} aria-label={ariaLabel}>
      {loading ? (
        <>
          {Array.from({ length: 3 }).map((_, index) => (
            <li key={index}>
              <Tag className={itemClassName} rounded={roundedTag} style={itemStyles} loading />
            </li>
          ))}
        </>
      ) : (
        <>
          {data.tags.map(({ icon, text, dataTestId }, index) => (
            <li key={index}>
              <Tag
                data={{ text, color: data.tagsColor }}
                dataTestId={dataTestId}
                icon={icon}
                className={itemClassName}
                rounded={roundedTag}
                style={itemStyles}
              />
            </li>
          ))}
        </>
      )}
    </ul>
  )
}
