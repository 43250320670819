'use client'

import Tag from '../Tag'
import { LinkTagListProps, TagListCommonProps } from './types'

export default function LinksTagList({
  data,
  loading,
  listClassName,
  itemClassName,
  itemStyles,
  ariaLabel,
  roundedTag = true,
}: LinkTagListProps & TagListCommonProps) {
  return (
    <ul className={listClassName} aria-label={ariaLabel}>
      {loading ? (
        <>
          {Array.from({ length: 3 }).map((_, index) => (
            <li key={index}>
              <Tag
                selectable
                asLink
                className={itemClassName}
                rounded={roundedTag}
                style={itemStyles}
                loading
              />
            </li>
          ))}
        </>
      ) : (
        <>
          {data.tags.map(({ icon, text, url, isActive, dataTestId }, index) => (
            <li key={index}>
              <Tag
                data={{ text, url, isActive, tabIndex: -1 }}
                dataTestId={dataTestId}
                icon={icon}
                selectable
                rounded={roundedTag}
                className={itemClassName}
                style={itemStyles}
                asLink
              />
            </li>
          ))}
        </>
      )}
    </ul>
  )
}
