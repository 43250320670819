export const sanitizeHtmlId = <T extends string | undefined | null>(
  id: T,
): T extends string ? string : undefined =>
  id
    ?.replace(/ü/g, 'ue')
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ß/g, 'ss')
    .replace(/[^a-zA-Z0-9-]/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-|-$/g, '')
    .toLowerCase() as T extends string ? string : undefined
