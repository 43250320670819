import Tag from '../Tag'
import { MultiSelectTagListProps, TagListCommonProps } from './types'

export default function MultiSelectTagList<T>({
  data,
  loading,
  listClassName,
  itemClassName,
  itemStyles,
  roundedTag = true,
  ariaLabel,
}: MultiSelectTagListProps<T> & TagListCommonProps) {
  return (
    <ul className={listClassName} aria-label={ariaLabel}>
      {loading ? (
        <>
          {Array.from({ length: 3 }).map((_, index) => (
            <li key={index}>
              <Tag
                selectable
                className={itemClassName}
                rounded={roundedTag}
                style={itemStyles}
                loading
              />
            </li>
          ))}
        </>
      ) : (
        <>
          {data.tags.map(({ icon, text, tagValue, dataTestId }, index) => (
            <li key={index}>
              <Tag
                data={{
                  text,
                  isChecked: data.value.includes(tagValue),
                  onSelect: () => data.onSelect(tagValue),
                  type: 'checkbox',
                  inputProps: data.inputProps,
                }}
                dataTestId={dataTestId}
                icon={icon}
                selectable
                className={itemClassName}
                rounded={roundedTag}
                style={itemStyles}
              />
            </li>
          ))}
        </>
      )}
    </ul>
  )
}
