import clsx from 'clsx'

import Star from '../Star'
import { getFillStarCoefficient } from './helpers'

type ReviewStarsProps = {
  score: number
  starSize: number
  ariaLabel?: string
  className?: string
}

export default function ReviewStars({ score, starSize, ariaLabel, className }: ReviewStarsProps) {
  return (
    <ul className={clsx('flex gap-1.5', className)} aria-label={ariaLabel || `${score}`}>
      {[...Array(5)].map((_, index) => (
        <li key={index} className='flex'>
          <Star size={starSize} fillCoefficient={getFillStarCoefficient(score, index)} />
        </li>
      ))}
    </ul>
  )
}
